<script>
export default {
  props: {
    recent: {
      type: Array,
      required: true,
    },
    popular: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header bg-transparent border-bottom">
      <div class="">
        <h5 class="card-title mt-2">Blogs</h5>
        <b-tabs
          nav-class="nav-tabs-custom card-header-tabs ms-auto"
          nav-wrapper-class="nav-item"
          align="right"
        >
          <b-tab title="Recent" active>
            <div class="card-body pb-1">
              <div data-simplebar style="max-height: 400px">
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item"
                    v-for="data of recent"
                    :key="data.blogName"
                  >
                    <div class="d-flex">
                      <div class="me-3">
                        <img
                          :src="data.thumbnail"
                          alt=""
                          class="avatar-md h-auto d-block rounded"
                        />
                      </div>

                      <div class="align-self-center overflow-hidden me-auto">
                        <div>
                          <h5 class="font-size-14 text-truncate">
                            <a href="#" class="text-dark">{{
                              data.blogName
                            }}</a>
                          </h5>
                          <p class="text-muted mb-0">{{ data.timestamp }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
          <b-tab title="Popular">
            <div class="card-body pb-1">
              <div data-simplebar style="max-height: 400px">
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item"
                    v-for="data of popular"
                    :key="data.blogName"
                  >
                    <div class="d-flex">
                      <div class="me-3">
                        <img
                          :src="data.thumbnail"
                          alt=""
                          class="avatar-md h-auto d-block rounded"
                        />
                      </div>

                      <div class="align-self-center overflow-hidden me-auto">
                        <div>
                          <h5 class="font-size-14 text-truncate">
                            <a href="#" class="text-dark">{{
                              data.blogName
                            }}</a>
                          </h5>
                          <p class="text-muted mb-0">{{ data.timestamp }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>