<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import dashboardSvc from "@/services/dashboard";

import Posts from "./posts";
import Activity from "./activity";
import BlogPost from "./blog-post";

/**
 * Blog dashboard component
 */
export default {
  page: {
    title: "Blog",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Posts,
    Activity,
    BlogPost,
  },
  data() {
    return {
      title: "Blog",
      dashboardInfo: {},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Blog",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      dashboardSvc.getVersion1().then((resp) => {
        if (resp.data.data === null) {
          return;
        }
        this.dashboardInfo = resp.data.data;
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-lg-2">
              <div class="card mini-stats-wid">
                <div class="card-body">
                  <div class="d-flex flex-wrap">
                    <div class="me-3">
                      <p class="text-muted mb-2">Total Blog</p>
                      <h5 class="mb-0">
                        {{
                          dashboardInfo.summary
                            ? dashboardInfo.summary.totalBlog
                            : "∞"
                        }}
                      </h5>
                    </div>

                    <div class="avatar-sm ms-auto">
                      <div
                        class="avatar-title bg-light rounded-circle text-primary font-size-20"
                      >
                        <i class="bx bxs-note"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-10">
              <div class="row">
                <div class="col-lg-2">
                  <div class="card blog-stats-wid">
                    <div class="card-body">
                      <div class="d-flex flex-wrap">
                        <div class="me-3">
                          <p class="text-muted mb-2">Deleted</p>
                          <h5 class="mb-0">
                            {{
                              dashboardInfo.summary
                                ? dashboardInfo.summary.totalDeleted
                                : "∞"
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="card blog-stats-wid">
                    <div class="card-body">
                      <div class="d-flex flex-wrap">
                        <div class="me-3">
                          <p class="text-muted mb-2">Publish</p>
                          <h5 class="mb-0">
                            {{
                              dashboardInfo.summary
                                ? dashboardInfo.summary.totalPublish
                                : "∞"
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="card blog-stats-wid">
                    <div class="card-body">
                      <div class="d-flex flex-wrap">
                        <div class="me-3">
                          <p class="text-muted mb-2">Pending Verify</p>
                          <h5 class="mb-0">
                            {{
                              dashboardInfo.summary
                                ? dashboardInfo.summary.totalPendingVerify
                                : "∞"
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="card blog-stats-wid">
                    <div class="card-body">
                      <div class="d-flex flex-wrap">
                        <div class="me-3">
                          <p class="text-muted mb-2">Rejected</p>
                          <h5 class="mb-0">
                            {{
                              dashboardInfo.summary
                                ? dashboardInfo.summary.totalRejected
                                : "∞"
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="card blog-stats-wid">
                    <div class="card-body">
                      <div class="d-flex flex-wrap">
                        <div class="me-3">
                          <p class="text-muted mb-2">Editing</p>
                          <h5 class="mb-0">
                            {{
                              dashboardInfo.summary
                                ? dashboardInfo.summary.totalEditing
                                : "∞"
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="card blog-stats-wid">
                    <div class="card-body">
                      <div class="d-flex flex-wrap">
                        <div class="me-3">
                          <p class="text-muted mb-2">Draft</p>
                          <h5 class="mb-0">
                            {{
                              dashboardInfo.summary
                                ? dashboardInfo.summary.totalDraft
                                : "∞"
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <Posts
            :recent="dashboardInfo.recent ? dashboardInfo.recent : []"
            :popular="dashboardInfo.popular ? dashboardInfo.popular : []"
          />
        </div>
        <div class="col-xl-4">
          <Activity
            :blogData="dashboardInfo.activity ? dashboardInfo.activity : []"
          />
        </div>

        <div class="col-xl-4">
          <BlogPost
            :blogData="dashboardInfo.popular ? dashboardInfo.popular : []"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>
