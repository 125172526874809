<script>
/**
 * Blog-post component
 */
export default {
  props: {
    blogData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <div class="me-2">
          <h5 class="card-title mb-4">Popular blogs</h5>
        </div>
        <!-- <b-dropdown
          toggle-tag="a"
          class="ms-auto"
          menu-class="dropdown-menu-end"
          toggle-class="font-size-16 p-0"
          variant="white"
          right
        >
          <template #button-content>
            <i class="mdi mdi-dots-horizontal"></i>
          </template>
          <b-dropdown-item href="#">Action</b-dropdown-item>
          <b-dropdown-item href="#">Another action</b-dropdown-item>
          <b-dropdown-item href="#">Something else here</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="#">Separated link</b-dropdown-item>
        </b-dropdown> -->
      </div>

      <div class="table-responsive" style="max-height: 400px">
        <table class="table align-middle table-nowrap mb-0">
          <tbody>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Views</th>
              <th scope="col" colspan="2">Blog</th>
            </tr>

            <tr v-for="(data, index) of blogData" :key="index">
              <td style="width: 100px">
                <img
                  :src="data.thumbnail"
                  alt=""
                  class="avatar-md h-auto d-block rounded"
                />
              </td>
              <td>
                <i class="bx bx-show align-middle me-1"></i>
                {{ data.views }}
              </td>
              <td>
                <h5 class="font-size-13 text-truncate mb-1">
                  <a href="#" class="text-dark">{{ data.blogName }}</a>
                </h5>
                <p class="text-muted mb-0">{{ data.timestamp }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- end card -->
</template>